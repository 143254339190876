import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import { FiTwitter, FiInstagram, FiGithub, FiCodepen } from 'react-icons/fi';
import { FaQuora, FaStackOverflow } from 'react-icons/fa';


const IndexPage = () => {
  const socialLinks = (
    <>
      <div className="social-icon-link px-2 text-3xl"><a aria-label="Pradeep CE on Twitter" href="https://twitter.com/pradeepcep"><FiTwitter /></a></div>
      <div className="social-icon-link px-2 text-3xl"><a aria-label="Pradeep CE on Instagram" href="https://instagram.com/pradeepcep"><FiInstagram /></a></div>
      <div className="social-icon-link px-2 text-3xl"><a  aria-label="Pradeep CE on Github"href="https://github.com/pradeepcep"><FiGithub /></a></div>
      <div className="social-icon-link px-2 text-3xl"><a aria-label="Pradeep CE on StackOverflow" href="https://stackoverflow.com/users/3186769/pradeepcep"><FaStackOverflow /></a></div>
      <div className="social-icon-link px-2 text-3xl hidden sm:flex md:hidden lg:flex"><a aria-label="Pradeep CE on Quora" href="https://quora.com/profile/Pradeep-CE"><FaQuora /></a></div>
      <div className="social-icon-link px-2 text-3xl hidden sm:flex md:hidden lg:flex"><a aria-label="Pradeep CE on Codepen" href="https://codepen.io/pradeepcep"><FiCodepen /></a></div>
      <div className="social-icon-link font-light px-2 pt-1 text-sm"><Link to="/social">view all →</Link></div>
    </>
  );
  return (
    <Layout>
      <SEO title="Pradeep CE" />

      <div className="flex flex-wrap flex-row">
        <div className="w-full md:w-1/2 lg:w-4/6 md:text-left flex flex-col items-stretch">
          <div className="m-auto lg:px-10">
            <h1 className="w-full flex flex-wrap px-10 pt-10 pb-1 xl:text-4xl">
              <span className="w-full text-5xl md:text-3xl lg:w-full lg:text-5xl lg:mb-6" role="img" aria-label="Wave">👋 &nbsp;</span>
              <span className="w-full lg:w-auto lg:py-0 py-3 xl:py-0">Hello, I'm &nbsp;</span>
              <span className="w-full lg:w-auto">Pradeep CE.</span>
            </h1>
            <p className="w-full body-copy px-10 py-1">
              Indie maker, solopreneur, travel enthusiast, and aspiring writer :)
            </p>
            <p className="w-full body-copy px-10 py-2">
              <Link to="/blog" className="my-6 bg-black hover:bg-gray-900 text-white hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
                read my blog →
              </Link>
            </p>
          </div>
          <div className="bg-gray-100 p-6 pl-8 mb-0 justify-left hidden md:flex lg:pl-20">
            {socialLinks}
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/6 md:p-0 pt-2 profile-image-wrapper">
          <Image />
        </div>
      </div>

      <div className="bg-gray-100 p-6 mb-4 flex flex-wrap justify-center md:hidden lg:px-18">
        {socialLinks}
      </div>

      <div className="flex flex-wrap md:py-10 lg:px-10 lg:py-20">
        <div className="w-full sm:w-1/2 sm:text-left body-copy px-10 py-6">
          <p>I am always building one thing or another — apps, circuit boards, services, libraries, tiny experiments to understand my stack better — so I decided to document them here for others to check out.</p>
          <Link to="/work" className="bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
            see my work →
          </Link>
        </div>
        <div className="w-full sm:w-1/2 sm:text-left body-copy px-10 py-6">
          <p>And the customary "about" page with everything the Internet might want to know about me. Tweet at me if we have a lot in common!</p>
          <Link to="/about" className="bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
            about me →
          </Link>
        </div>
        <div className="w-full sm:w-1/2 sm:text-left body-copy px-10 py-6">
          <p>I dabble in poetry and prose quite a bit, and recently published a book!</p>
          <Link to="/books" className="bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
            see books →
          </Link>
        </div>
        <div className="w-full sm:w-1/2 sm:text-left body-copy px-10 py-6">
          <p>So, what am I hacking on at the moment?</p>
          <Link to="/now" className="bg-transparent hover:bg-black text-black hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
            now →
          </Link>
        </div>
      </div>

      <div className="bg-gray-100 px-10 py-10 mt-10 sm:text-left lg:px-20">
        <p className="body-copy pt-10">
          <big className="font-bold">Perpetually curious, hungry and foolish, one day I hope to put a ding in the Universe.</big>
        </p>
        <p className="body-copy py-3">
          As you might have guessed by now, I love learning new things and solving problems with hardware and software.
          I taught myself Python and Javascript from the Web, and since then, I have been designing and building soulful web applications.
        </p>
        <p className="body-copy py-3">
          If what I do and write about sounds interesting to you, or if you want to be the first to hear about my new projects,
          please leave your email here. I won't sell your email address or send you spam: promise!
        </p>
        <form action="https://pradeepcep.us10.list-manage.com/subscribe/post?u=d036ed74e1b9a03356b2e201a&amp;id=7fdb66e1e1" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
          <input type="email" name="EMAIL" className="text-l appearance-none border rounded w-full py-2 px-3 text-black leading-tight border-gray-600 focus:outline-none focus:border-black" id="magic-phrase" placeholder="awesome.joe@example.com" required />
          <div style={{ position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_d036ed74e1b9a03356b2e201a_7fdb66e1e1" tabIndex="-1" value="" onChange={() => {}}/></div>
          <button type="submit" className="my-6 bg-black hover:bg-gray-900 text-white hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
            hear from me →
          </button>
        </form>
      </div>
    </Layout>
  );
}

export default IndexPage
